import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import BreadcrumbComponent from '../../components/ProductScreenComponents/Breadcrumb'
import { useStylesProducts } from '../../components/ProductsSection/styles'
import { useStyles } from './ProductPage.styles'
import { formatNumber } from '../../utils/formatNumber'
import {
  Grid,
  Button,
  Divider,
  Typography,
  Box,
  useMediaQuery,
  TextField,
  CircularProgress,
  Skeleton,
  Tooltip,
} from '@mui/material'
import Tabla from '../../components/Tabla'
import sinImagen from '../../assets/sinImagen.jpg'
import creditCard from '../../assets/creditCard.jpg'
import SizeComponent from '../../components/SizeComponent/SizeComponent'
import AddMinusQuantity from '../../components/AddMinusQuantity'
import { addItemToCart, removeItemFromCart } from 'stores/cartStore/actionsCart'
import {
  detailsProduct,
  listProducts,
} from 'stores/productStore/actionsProducts'
import MetodosDePagoModal from './MetodosDePagoModal'
import CaravanasOficiales from 'components/CaravanasCheckout/CaravanasOficiales'
import CaravanasNoOficiales from 'components/CaravanasCheckout/CaravanasNoOficiales'
import { setSnackbar } from 'stores/snackbarStore'
import ConsultarProducto from 'components/ConsultarProducto/ConsultarProducto'
import {
  selectCurrentBranch,
  updateBranchList,
} from 'stores/userStore/actionsUser'
import CaravanasCombinadas from 'components/CaravanasCheckout/CaravanasCombinadas'
import {
  noStockMessage,
  productWithCotizacionInDollars,
} from 'utils/noStockMessage'
import { getAllProductDiscount } from './utils/getProductDiscounts'
import DiscountLabel from 'components/DiscountLabel/index'
import { getProductsToAdd } from './utils/getProductsToAdd'
import { fixAmount } from './utils/helpers'
import { debounce } from 'utils/debounce'
import NoResults from 'components/NoResults/NoResults'
import ProductImageSection from './ProductImageSection'
import useProductPrice from 'customHooks/useProductPrice'
import { shouldHidePrice } from 'utils/priceVisibilityHelper'
import { ApiConnection } from 'config/index'
import InfoIcon from '@mui/icons-material/Info'


const CONSULTAR_PRODUCTO_MODAL = 'CONSULTAR_PRODUCTO_MODAL'
const CARAVANAS_OFICIALES_MODAL = 'CARAVANAS_OFICIALES_MODAL'
const CARAVANAS_NO_OFICIALES_MODAL = 'CARAVANAS_NOOFICIALES_MODAL'
const CARAVANAS_COMBINADAS = 'CARAVANAS_COMBINADAS'
const METODOS_PAGO_MODAL = 'METODOS_PAGO_MODAL'

const skuOfOneHundredPack = [
  '243545',
  '243565',
  '243555',
  '243540',
  '243560',
  '243550',
]

const skuOfTenPack = ['227635']

export const ProductDetailsSection = ({
  compraRapida,
  closeCompraRapidaModal,
}) => {
  const [modalOpen, setModal] = useState('')
  const classes = useStylesProducts()
  const styles = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    noSsr: true,
  })
  const { sku: productSkuCompraRapida } = useSelector(
    (state) => state.app.modales.compraRapida
  )
  const currentImage = useRef(0)
  const itemsDesktop = 12

  const params = useParams()
  const { sku: productSkuUrlParam } = params
  const productSku = compraRapida ? productSkuCompraRapida : productSkuUrlParam

  // STATE
  const [quantity, setQuantity] = useState(1)
  const [selectedImage, setSelectedImage] = useState(0)
  const [forQuantity, setForQuantity] = useState(1)
  const [sellerDiscount, setSellerDiscount] = useState(0)
  const [discountInfo, setDiscountInfo] = useState({})
  const [currentStock, setCurrentStock] = useState(0)
  const [batches, setBatches] = useState({})
  const [downloadingImg, setDownloadingImg] = useState(false)
  const [selectedCharacteristics, setSelectedCharacteristics] = useState({})

  // REDUX SELECTORS
  const { product, loading } = useSelector(
    ({ productDetails }) => productDetails
  )
  const { userInfo, signedIn, currentBranch } = useSelector(
    ({ userAuth }) => userAuth
  )
  const cart = useSelector((store) => store.cart)
  const [loadingToNavigate, setLoadingToNavigate] = useState(false)
  const [isAddProductLoading, setIsAddProductLoading] = useState(false)
  const [redirectToCart, setRedirectToCart] = useState(false)
  const [variantSelectedIndex, setVariantSelectedIndex] = useState(null)

  const variantToBuy = product?.variantes?.[variantSelectedIndex || 0]

  const imgToShow =
    variantSelectedIndex === null ? product?.imagen : variantToBuy?.imagen

  const productToShow = variantToBuy || product?.variantes?.[0] || product

  const { priceToShow, priceApplyPromotions } = useProductPrice(productToShow)

  const openModal = (modal) => setModal(modal)
  const closeModal = () => setModal(null)

  const packQuantity = useMemo(() => {
    // if(product){
    //   return (product.hasta - product.desde) +1
    // }
    let basicQuantity = 25
    if (skuOfOneHundredPack.includes(productSku)) basicQuantity = 100
    else if (skuOfTenPack.includes(productSku)) {
      basicQuantity = 10
    }
    return basicQuantity
  }, [product, productSku])

  // USE EFFECT
  const stocks = [
    {
      name: 'Bahía Blanca',
      cod: 'BB',
      quantity: parseInt(productToShow?.stockBB),
    },
    {
      name: 'Capital Federal',
      cod: 'CF',
      quantity: parseInt(productToShow?.stockCF),
    },
    {
      name: 'General Pico',
      cod: 'GP',
      quantity: parseInt(productToShow?.stockGP),
    },
    {
      name: 'Rafaela',
      cod: 'RA',
      quantity: parseInt(productToShow?.stockRA),
    },
    {
      name: 'Resistencia',
      cod: 'RS',
      quantity: parseInt(productToShow?.stockRS),
    },
    {
      name: 'Villa María',
      cod: 'VM',
      quantity: parseInt(productToShow?.stockVM),
    },
    {
      name: 'Depósito Central',
      cod: 'DC',
      quantity: parseInt(productToShow?.stockDC),
    },
  ]

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        const response = await ApiConnection.get(
          `product/batches/${productSku}`
        )
        const fetchedData = await response.data
        const groupedBatches = fetchedData.response.reduce((acc, warehouse) => {
          acc[warehouse.warehouseCode] = warehouse.batches.map((batch) => ({
            expirationDate: batch.expirationDate,
            quantity: batch.quantity,
          }))
          return acc
        }, {})
        setBatches(groupedBatches)
      } catch (error) {
        console.error('Error fetching batch data:', error)
      }
    }

    if (productSku) {
      fetchBatches()
    }
  }, [productSku])

  useEffect(() => {
    if (!productSku) return
    dispatch(detailsProduct(productSku, userInfo?.codigoUsuario))
    setSellerDiscount(0)
    setQuantity(1)
  }, [dispatch, productSku, userInfo?.codigoUsuario])

  useEffect(() => {
    setForQuantity(productToShow?.tipo === 'product' ? null : 1)
  }, [productToShow?.tipo])

  useEffect(() => {
    if (
      productToShow &&
      signedIn &&
      currentBranch?.cod &&
      priceApplyPromotions
    ) {
      const allProductDiscounts = getAllProductDiscount(
        { ...productToShow, precioDeLista: priceToShow, cantidad: 1 },
        cart,
        userInfo?.ofertas,
        userInfo?.promociones,
        null,
        currentBranch?.cod
      )
      const discount = allProductDiscounts?.secondaryDiscount
        ? allProductDiscounts?.secondaryDiscount
        : allProductDiscounts?.mainDiscount

      setDiscountInfo(discount)
    }
    if (!priceApplyPromotions) {
      setDiscountInfo({})
    }
  }, [
    product,
    userInfo?.ofertas,
    userInfo?.promociones,
    cart,
    signedIn,
    currentBranch?.cod,
    productToShow?.id,
    priceToShow,
    priceApplyPromotions,
  ])

  useEffect(() => {
    if (currentBranch.cod && product) {
      const stockField = 'stock' + currentBranch.cod
      if (productToShow?.[stockField]) {
        setCurrentStock(parseInt(product[stockField]))
      } else {
        setCurrentStock(0)
      }
    }
    dispatch(
      listProducts(
        1,
        itemsDesktop,
        false,
        { tipo: productToShow?.tipo, linea: productToShow?.linea },
        'product',
        userInfo?.codigoUsuario
      )
    )
  }, [product, currentBranch])

  const handleEndAction = () => {
    setIsAddProductLoading(false)
    setLoadingToNavigate(false)
    if (compraRapida && !variantToBuy) {
      closeCompraRapidaModal()
    }
  }
  function findVariantAccordToCharacteristics(
    selected = selectedCharacteristics
  ) {
    if (Object.keys(selected).length !== caracteristicas.length) return null

    return product.variantes.findIndex((variante) => {
      if (!variante?.caracteristicas?.length) return false
      return variante.caracteristicas
        .filter((c) =>
          product.caracteristicas
            ? product.caracteristicas?.some(({ nombre }) => c.nombre === nombre)
            : true
        )
        .every((c) => {
          const value = c.values[0]?.codigo
          return selected[c.codigo] === value
        })
    })
  }

  const dispatchAddItem = (extraData = {}, loadingToNavigate = true) => {
    setLoadingToNavigate(loadingToNavigate)
    if (currentBranch?.openedCart !== cart.idOrder) {
      const localData = localStorage.getItem('userInfo')
      const localBranchData = JSON.parse(localData)?.sucursales
      const sucursales = localBranchData?.map((branch) => {
        if (branch?.cod === currentBranch?.cod)
          return { ...currentBranch, openedCart: cart.idOrder }
        return branch
      })
      localStorage.setItem(
        'userInfo',
        JSON.stringify({ ...JSON.parse(localData), sucursales })
      )
      dispatch(
        selectCurrentBranch({ ...currentBranch, openedCart: cart.idOrder })
      )
      dispatch(updateBranchList(sucursales))
    }

    const cantidad =
      productToShow?.tipo && productToShow?.tipo !== 'product'
        ? packQuantity * forQuantity
        : quantity
    const productToPurchase = productToShow

    if (!productToPurchase) return

    const productItem = {
      sku: productToPurchase?.sku,
      precioEcommerce: productToPurchase?.precioEcommerce,
      precioDeLista: productToPurchase?.precioDeLista,
      cantidad,
      descuento: productToPurchase?.descuento,
      IVA: productToPurchase?.iva || 0,
      nombre: productToPurchase?.nombreEC || productToPurchase?.nombre,
      idProducto: productToPurchase?.id,
      clase: productToPurchase?.clase ?? null,
      imagen: productToPurchase?.imagen || [],
      grupoDto: productToPurchase?.grupoDto,
      nombreEC: productToPurchase?.nombreEC || productToPurchase?.nombre,
      sucursal: currentBranch?.cod,
      tipo: productToPurchase?.tipo,
      linea: productToPurchase?.linea,
      subLinea: productToPurchase?.subLinea,
      rubro: productToPurchase?.rubro,
    }

    const productsToAdd = getProductsToAdd(
      productItem,
      cart,
      userInfo?.promociones,
      userInfo?.ofertas,
      sellerDiscount,
      currentBranch?.cod
      // true
    )
    productsToAdd.map((product) => {
      if (product.cantidad > 0) {
        const parent = variantToBuy ? String(productSku) : null

        dispatch(
          addItemToCart(
            {
              [productToShow?.tipo ?? 'product']: {
                ...product,
                ...extraData,
                sellerDiscount: sellerDiscount || undefined,
              },
            },
            {
              idUsuario: userInfo?.codigoUsuario,
              idOrder: cart.idOrder,
            },
            handleEndAction,
            parent
          )
        )
      } else {
        dispatch(
          removeItemFromCart(
            { [productToShow?.tipo ?? 'product']: product },
            handleEndAction
          )
        )
      }
    })
  }

  useEffect(() => {
    console.log({redirectToCart})
    if (redirectToCart && !loadingToNavigate && !modalOpen && !compraRapida) {
      navigate('/carrito')
    }
  }, [redirectToCart, loadingToNavigate])

  const handleDownloadImg = async () => {
    setDownloadingImg(true)

    try {
      const currentImgIndex = selectedImage
      const imgToDownload = product.imagen[currentImgIndex]

      if (!imgToDownload) {
        dispatch(
          setSnackbar(true, 'error', 'No hay imagen disponible para descargar.')
        )
        setDownloadingImg(false)
        return
      }

      const fileName = imgToDownload.split('/api/files/imagen/')[1]
      const response = await fetch(imgToDownload)
      const blob = await response.blob()

      // Create a link element
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName

      document.body.appendChild(downloadLink)
      downloadLink.click()

      // Clean up
      document.body.removeChild(downloadLink)
      window.URL.revokeObjectURL(downloadLink.href)

      dispatch(setSnackbar(true, 'success', 'Imagen descargada.'))
    } catch (error) {
      console.error('Error downloading image:', error)
      dispatch(setSnackbar(true, 'error', 'Error al descargar la imagen.'))
    } finally {
      setDownloadingImg(false)
    }
  }

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href)
    dispatch(
      setSnackbar(
        true,
        'success',
        'Enlace del producto copiado al portapapeles.'
      )
    )
  }

  const handleSellerDiscountChange = (e) => {
    const input = e.target.value
    if (input >= 0 && input <= 100) {
      setSellerDiscount(input)
    } else {
      dispatch(setSnackbar(true, 'error', 'Ingrese un descuento válido'))
    }
  }

  const handleOpenCaravanModal = (productType) => {
    if (productType?.includes('official')) {
      return openModal(CARAVANAS_OFICIALES_MODAL)
    }
    if (productType?.includes('manejo')) {
      return openModal(CARAVANAS_NO_OFICIALES_MODAL)
    }
    return openModal(CARAVANAS_COMBINADAS)
  }

  const caracteristicas = useMemo(() => {
    if (!product?.variantes) return []
    const result = {}

    product.variantes.forEach((variante) => {
      variante.caracteristicas
        .filter((c) =>
          product.caracteristicas
            ? product.caracteristicas?.some(({ nombre }) => c.nombre === nombre)
            : true
        )
        .forEach((c) => {
          const values = [...c.values]

          if (result[c.codigo]) {
            values.forEach((val) => {
              const valAdded = result[c.codigo].values.find(
                ({ codigo }) => val.codigo === codigo
              )
              if (!valAdded) result[c.codigo].values.push(val)
            })
            return
          }
          result[c.codigo] = structuredClone(c)
        })
    })
    return Object.values(result)
  }, [product?.variantes])


  const specialPrice = Number(priceToShow ?? 0) < Number(productToShow?.precioDeLista ?? 0)
  const discountType = productToShow?.combos ? 'combo' : discountInfo?.discountType

  const hidePrice = useMemo(() => shouldHidePrice(userInfo?.user, productSku), [
    userInfo?.user,
    productSku,
  ])


  return (
    <>
      <ConsultarProducto
        open={modalOpen === CONSULTAR_PRODUCTO_MODAL}
        onClose={closeModal}
        productName={productToShow?.nombre}
        productSku={productToShow?.sku}
      />
      <CaravanasOficiales
        open={modalOpen === CARAVANAS_OFICIALES_MODAL}
        onClose={closeModal}
        onSave={dispatchAddItem}
        numberTo={forQuantity * packQuantity}
        packQuantity={packQuantity}
        compraRapida={compraRapida}
      />
      <CaravanasNoOficiales
        open={modalOpen === CARAVANAS_NO_OFICIALES_MODAL}
        onClose={closeModal}
        onSave={dispatchAddItem}
        numberTo={forQuantity * packQuantity}
        packQuantity={packQuantity}
        compraRapida={compraRapida}
      />
      <CaravanasCombinadas
        open={modalOpen === CARAVANAS_COMBINADAS && product?.caravCombinadas}
        showFromNumber={product?.caravSinRangoSen}
        onClose={closeModal}
        onSave={dispatchAddItem}
        numberTo={forQuantity * packQuantity}
        packQuantity={packQuantity}
      />
      <MetodosDePagoModal
        open={modalOpen === METODOS_PAGO_MODAL}
        handleClose={closeModal}
      />
      {loading ? (
        <Grid item xs={12}>
          <Grid className={styles.productContainer} style={{ marginTop: 20 }}>
            {desktop ? (
              <Grid
                className={styles.firstContainer}
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  width={'600px'}
                  height={compraRapida ? '600px' : '700px'}
                />
                <Grid style={{ justifySelf: 'center' }}>
                  <Box>
                    <Skeleton
                      variant='rounded'
                      animation='wave'
                      width={'300px'}
                      height={'100px'}
                      style={{ marginBottom: 20, marginTop: 20 }}
                    />
                    <Skeleton
                      variant='rounded'
                      animation='wave'
                      width={'283px'}
                      height={'283px'}
                      style={{ marginBottom: 80, marginTop: 30 }}
                    />
                    <Skeleton
                      variant='rounded'
                      animation='wave'
                      width={'300px'}
                      height={'60px'}
                      style={{ marginBottom: 15, borderRadius: 5 }}
                    />
                    <Skeleton
                      variant='rounded'
                      animation='wave'
                      width={'300px'}
                      height={'60px'}
                      style={{ borderRadius: 5 }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid
                className={styles.firstContainer}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Skeleton
                  variant='rounded'
                  animation='wave'
                  width={'283px'}
                  height={'283px'}
                />
                <Box>
                  <Skeleton
                    variant='rounded'
                    animation='wave'
                    width={'283px'}
                    height={'100px'}
                    style={{ marginBottom: 10, marginTop: 20 }}
                  />
                  <Skeleton
                    variant='rounded'
                    animation='wave'
                    width={'283px'}
                    height={'60px'}
                    style={{ marginBottom: 15, borderRadius: 5 }}
                  />
                  <Skeleton
                    variant='rounded'
                    animation='wave'
                    width={'283px'}
                    height={'60px'}
                    style={{ borderRadius: 5 }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {!product && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <NoResults />
              <button
                style={{
                  background: '#4AA259',
                  borderRadius: '6px',
                  padding: '19px 141px',
                  border: 'none',
                  color: 'white',
                }}
                onClick={() => navigate(-1)}
              >
                Volver
              </button>
            </Box>
          )}
          {product && (
            <>
              {!compraRapida && (
                <BreadcrumbComponent
                  productName={product?.nombreEC || product?.nombre}
                  middleBreadCrumb={productToShow?.menuCategorias}
                />
              )}

              <Grid className={styles.productContainer}>
                <DiscountLabel discountType={discountType} />
                <Grid className={styles.firstContainer}>
                  <ProductImageSection
                    productToShow={{ ...productToShow, imagen: imgToShow }}
                    sinImagen={sinImagen}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    downloadingImg={downloadingImg}
                    handleDownloadImg={handleDownloadImg}
                    handleShare={handleShare}
                    showDiscountLabel={!!discountType}
                  />
                  <Grid
                    container
                    flexDirection='column'
                    className={styles.productOptionsBox}
                    style={compraRapida ? { marginBottom: 0 } : {}}
                  >
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: '#444444',
                        lineHeight: '24px',
                      }}
                    >
                      {product?.nombreEC || product?.nombre}
                    </Typography>
                    <Typography paragraph className={styles.skuTitle}>
                      SKU {product?.sku}
                    </Typography>

                    {signedIn && discountInfo?.discount && !hidePrice ? (
                      <Typography
                        paragraph
                        component='strike'
                        gutterBottom={false}
                        classes={{ paragraph: classes.productName }}
                        className={styles.discountNumber}
                      >
                        {formatNumber(
                          priceToShow,
                          productToShow?.moneda || 'ARS'
                        ) ?? 0}
                      </Typography>
                    ) : null}
                    <Grid
                      container
                      spacing={0}
                      alignItems='center'
                      sx={{ marginBottom: '12px' }}
                    >
                      {signedIn && !hidePrice && (
                        <>
                          {discountInfo?.discount ? (
                            <>
                              <Typography
                                paragraph
                                classes={{ paragraph: classes.productName }}
                                className={styles.priceNumber}
                                style={compraRapida ? { fontSize: '30px' } : {}}
                              >
                                {formatNumber(
                                  discountInfo?.priceWithDiscount.toString(),
                                  productToShow?.moneda || 'ARS'
                                ) ?? 0}
                              </Typography>
                              <Typography
                                paragraph
                                className={styles.percentage}
                                style={compraRapida ? { fontSize: '14px' } : {}}
                              >
                                {`${fixAmount(discountInfo?.discount)}%`} OFF
                              </Typography>
                            </>
                          ) : (
                            <div>
                              {specialPrice && (
                                <Typography
                                  paragraph
                                  component='strike'
                                  gutterBottom={false}
                                  classes={{ paragraph: classes.productName }}
                                  className={styles.discountNumber}
                                >
                                  {formatNumber(
                                    productToShow?.precioDeLista,
                                    productToShow?.moneda || 'ARS'
                                  ) ?? 0}
                                </Typography>
                              )}
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography
                                  paragraph
                                  classes={{ paragraph: classes.productName }}
                                  className={styles.priceNumber}
                                >
                                  {formatNumber(
                                    String(priceToShow),
                                    productToShow?.moneda || 'ARS'
                                  ) ?? 0}
                                </Typography>
                                {specialPrice && (
                                  <Typography
                                    paragraph
                                    className={styles.percentage}
                                    style={compraRapida ? { fontSize: '14px' } : {}}
                                  >
                                    PRECIO ESPECIAL
                                  </Typography>
                                )}
                              </div>
                            </div>
                          )}
                          <Typography paragraph className={styles.unitPrice}>
				{/* Precio por unidad:{' '}
                            {formatNumber(
                              discountInfo?.discount
                                ? discountInfo?.priceWithDiscount.toString()
                                : priceToShow,
                              productToShow?.moneda || 'ARS'
                            )} */}
                          </Typography>
                        </>
                      )}
                      {/* <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => openModal(METODOS_PAGO_MODAL)}
                      >
                        <img
                          src={creditCard}
                          alt='tarjeta de credito'
                          style={{ marginRight: '8px' }}
                        />
                        <Typography
                          component='span'
                          classes={{ root: classes.paymentMethod }}
                        >
                          Ver los medios de pago
                        </Typography>
                      </Box> */}
                      {signedIn && userInfo.permisos !== '2' && !hidePrice && (

                        <div>
                          <Grid className={styles.cantidadBox}>
                            <Typography
                              component='span'
                              classes={{ root: classes.productName }}
                              className={styles.cantidadText}
                            >
                              Cantidad
                            </Typography>
                            <AddMinusQuantity
                              cartQuantity={
                                forQuantity !== null
                                  ? packQuantity * forQuantity
                                  : quantity
                              }
                              minusItem={() => {
                                if (
                                  quantity > 1 &&
                                  productToShow?.tipo === 'product'
                                )
                                  setQuantity((quantity) => quantity - 1)
                                else if (forQuantity >= 2)
                                  setForQuantity(
                                    (forQuantity) => forQuantity - 1
                                  )
                              }}
                              plusItem={() => {
                                if (productToShow?.tipo === 'product')
                                  setQuantity((quantity) => quantity + 1)
                                else
                                  setForQuantity(
                                    (forQuantity) => forQuantity + 1
                                  )
                              }}
                              onChangeHandler={(e) => {
                                const { value } = e.target
                                setQuantity(value)
                                if (forQuantity !== null) {
                                  setForQuantity(null)
                                }
                                const debounceCb = debounce(
                                  (prev) => {
                                    if (productToShow?.tipo !== 'product') {
                                      const fQ =
                                        Math.round(
                                          Number(prev || 1) / packQuantity
                                        ) || 1
                                      setForQuantity(fQ)
                                    } else {
                                      setQuantity(Number(prev || 1))
                                    }
                                  },
                                  productToShow?.tipo === 'product'
                                    ? 5000
                                    : 2500
                                )
                                debounceCb(value)
                              }}
                              width={'129px'}
                              height={'45px'}
                            />
                          </Grid>
                          {currentStock < quantity &&
                            productToShow?.tipo === 'product' && (
                              <Grid sx={{ marginTop: '14px' }}>
                                <Typography
                                  variant='p'
                                  sx={{ fontWeight: 500, color: '#f54c36' }}
                                >
                                  {noStockMessage}
                                </Typography>
                              </Grid>
                            )}

                          {productToShow?.moneda === 'USD' && (
                            <Grid sx={{ marginTop: '14px' }}>
                              <Typography
                                variant='p'
                                sx={{ fontWeight: 500, color: '#00b050' }}
                              >
                                {productWithCotizacionInDollars}
                              </Typography>
                            </Grid>
                          )}
                        </div>
                      )}

                      {caracteristicas.map((caracteristica) => (
                        <Grid
                          key={caracteristica.codigo}
                          container
                          className={styles.sizeBox}
                          direction='column'
                          justifyContent='flex-start'
                        >
                          <Typography
                            component='span'
                            classes={{ root: classes.productName }}
                            className={styles.sizeText}
                          >
                            Elegí el {caracteristica.nombre}
                          </Typography>
                          <SizeComponent
                            options={caracteristica.values}
                            onClick={(selected) => {
                              setSelectedCharacteristics({
                                ...selectedCharacteristics,
                                [caracteristica.codigo]: selected.codigo,
                              })
                              setVariantSelectedIndex(
                                findVariantAccordToCharacteristics({
                                  ...selectedCharacteristics,
                                  [caracteristica.codigo]: selected.codigo,
                                })
                              )
                            }}
                            selected={
                              selectedCharacteristics[caracteristica.codigo]
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid>
                      {productToShow?.stock !== 'Y' &&
                      signedIn &&
                      !hidePrice ? (
                        <>
                          {(localStorage.getItem('permisos') === '2' ||
                            userInfo.permisos === '2') && (
                            <>
                              {productToShow?.tipo === 'product' && (
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: '#444',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <div>
                                    {stocks.map((stock) => {
                                      const stockBatches = batches[stock.cod]
                                      const hasBatches =
                                        stockBatches && stockBatches.length > 0

                                      // return hasBatches ? (
                                      //   <Tooltip
                                      //     key={stock.cod}
                                      //     title={
                                      //       <div>
                                      //         {stockBatches.map(
                                      //           (batch, index) => {
                                      //             const formattedDate = `${batch.expirationDate.slice(
                                      //               6,
                                      //               8
                                      //             )}/${batch.expirationDate.slice(
                                      //               4,
                                      //               6
                                      //             )}/${batch.expirationDate.slice(
                                      //               0,
                                      //               4
                                      //             )}`

                                      //             return (
                                      //               <div key={index}>
                                      //                 Lote {index + 1},
                                      //                 Vencimiento:{' '}
                                      //                 {formattedDate}, Cantidad:{' '}
                                      //                 {batch.quantity}
                                      //               </div>
                                      //             )
                                      //           }
                                      //         )}
                                      //       </div>
                                      //     }
                                      //     arrow
                                      //   >
                                      //     <div>
                                      //       Stock en {stock.name} :{' '}
                                      //       {stock.quantity}
                                      //     </div>
                                      //   </Tooltip>
                                      // ) : (
                                      //   <div key={stock.cod}>
                                      //     Stock en {stock.name} :{' '}
                                      //     {stock.quantity}
                                      //   </div>
                                      // )
                                      return (
                                        <div
                                          key={stock.cod}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <div>
                                            Stock en {stock.name}:{' '}
                                            {stock.quantity}
                                          </div>
                                          {hasBatches && (
                                            <Box
                                              sx={{
                                                marginLeft: '8px',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <Tooltip
                                                title={
                                                  <div>
                                                    {stockBatches.map(
                                                      (batch, index) => {
                                                        const formattedDate = batch.expirationDate
                                                          ? `${batch.expirationDate.slice(
                                                              6,
                                                              8
                                                            )}/${batch.expirationDate.slice(
                                                              4,
                                                              6
                                                            )}/${batch.expirationDate.slice(
                                                              0,
                                                              4
                                                            )}`
                                                          : 'Sin fecha de vencimiento'

                                                        return (
                                                          <div key={index}>
                                                            Lote {index + 1},
                                                            Vencimiento:{' '}
                                                            {formattedDate},
                                                            Cantidad:{' '}
                                                            {batch.quantity}
                                                          </div>
                                                        )
                                                      }
                                                    )}
                                                  </div>
                                                }
                                                arrow
                                                enterTouchDelay={0}
                                              >
                                                <InfoIcon
                                                  sx={{
                                                    fontSize: '16px',
                                                    color: '#444',
                                                    cursor: 'pointer',
                                                  }}
                                                />
                                              </Tooltip>
                                            </Box>
                                          )}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </Typography>
                              )}
                              {localStorage.getItem('permisos') === '2' && (
                                <div>
                                  <TextField
                                    inputProps={{ style: { fontSize: '14px' } }}
                                    label='Aplicar nuevo descuento (%)'
                                    type='number'
                                    size='small'
                                    value={sellerDiscount}
                                    onChange={handleSellerDiscountChange}
                                    sx={{ marginTop: '20px' }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {userInfo.permisos !== '2' && (
                            <div>
                              {!compraRapida && (
                                <Button
                                  onClick={() => {
                                    if (caracteristicas.length > 0) {
                                      if (
                                        Object.keys(selectedCharacteristics)
                                          .length !== caracteristicas.length
                                      ) {
                                        setLoadingToNavigate(false)
                                        return dispatch(
                                          setSnackbar(
                                            true,
                                            'error',
                                            'Debe seleccionar las caracteristicas a comprar'
                                          )
                                        )
                                      }
                                    }
                                    setRedirectToCart(true)
                                    if (
                                      productToShow?.tipo &&
                                      productToShow?.tipo !== 'product'
                                    )
                                      return handleOpenCaravanModal(
                                        productToShow?.tipo
                                      )
                                    dispatchAddItem()
                                  }}
                                  variant='primary'
                                  disabled={
                                    loadingToNavigate ||
                                    productToShow?.tipo === 'product'
                                      ? !quantity
                                      : !forQuantity
                                  }
                                  sx={(theme) => ({
                                    marginTop: '44px',
                                    [theme.breakpoints.up('sm')]: {
                                      marginTop: '30px',
                                      padding: '19px 20px',
                                      fontSize: '16px',
                                    },
                                  })}
                                >
                                  {loadingToNavigate ? (
                                    <CircularProgress
                                      disableShrink
                                      size={22}
                                      color='inherit'
                                    />
                                  ) : (
                                    'Comprar ahora'
                                  )}
                                </Button>
                              )}
                              <Button
                                sx={(theme) => ({
                                  marginTop: '14px',
                                  [theme.breakpoints.up('sm')]: {
                                    padding: '19px 20px',
                                    fontSize: '16px',
                                  },
                                })}
                                variant={compraRapida ? 'primary' : 'secondary'}
                                disabled={
                                  isAddProductLoading ||
                                  productToShow?.tipo === 'product'
                                    ? !quantity
                                    : !forQuantity
                                }
                                onClick={() => {
                                  if (caracteristicas.length > 0) {
                                    if (
                                      Object.keys(selectedCharacteristics)
                                        .length !== caracteristicas.length
                                    ) {
                                      setLoadingToNavigate(false)
                                      return dispatch(
                                        setSnackbar(
                                          true,
                                          'error',
                                          'Debe seleccionar las caracteristicas a comprar'
                                        )
                                      )
                                    }
                                  }
                                  if (
                                    productToShow?.tipo &&
                                    productToShow?.tipo !== 'product'
                                  )
                                    return handleOpenCaravanModal(
                                      productToShow?.tipo
                                    )
                                  dispatchAddItem({}, false)
                                  setIsAddProductLoading(true)
                                }}
                              >
                                {isAddProductLoading ? (
                                  <CircularProgress
                                    disableShrink
                                    size={22}
                                    color='inherit'
                                  />
                                ) : (
                                  'Agregar al carrito'
                                )}
                              </Button>
                            </div>
                          )}
                        </>
                      ) : !signedIn || hidePrice ? (
                        <Button
                          variant='primary'
                          onClick={() => openModal(CONSULTAR_PRODUCTO_MODAL)}
                          sx={(theme) => ({
                            marginTop: '20px',
                            fontSize: '16px',
                          })}
                        >
                          Consultanos!
                        </Button>
                      ) : (
                        <Typography>Stock no disponible</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {!compraRapida && (
                  <>
                    {!desktop && (
                      <Divider
                        sx={{ marginTop: '29px', marginBottom: '44px' }}
                      />
                    )}
                    {(productToShow.fichaTecnica ||
                      productToShow.guiaDeUso) && (
                      <Grid
                        sx={(theme) => ({
                          marginBottom: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          [theme.breakpoints.up('sm')]: {
                            justifyContent: 'start',
                          },
                        })}
                      >
                        {productToShow.fichaTecnica && (
                          <Button
                            sx={(theme) => ({
                              width: '150px',
                              marginRight: '12px',
                              fontSize: '14px',
                              [theme.breakpoints.up('sm')]: {
                                fontSize: '16px',
                                width: '180px',
                              },
                            })}
                            variant='primary'
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${productToShow.fichaTecnica}`,
                                '_blank'
                              )
                            }
                          >
                            Ficha técnica
                          </Button>
                        )}
                        {productToShow.guiaDeUso && (
                          <Button
                            sx={(theme) => ({
                              width: '150px',
                              fontSize: '14px',
                              [theme.breakpoints.up('sm')]: {
                                fontSize: '16px',
                                width: '180px',
                              },
                            })}
                            variant='primary'
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_URL_BACKEND_REMOTO}/files/${productToShow.guiaDeUso}`,
                                '_blank'
                              )
                            }
                          >
                            Guía de Uso
                          </Button>
                        )}
                      </Grid>
                    )}
                    <Box className={styles.productInfoContainer}>
                      {productToShow?.caracteristicas && (
                        <Grid
                          container
                          spacing={0}
                          alignItems='center'
                          style={{ marginBottom: '12px' }}
                        >
                          <Typography className={styles.productInfoTextTitle}>
                            Informacion del producto
                          </Typography>
                          <Tabla
                            rows={
                              productToShow?.caracteristicas ?? [
                                { nombre: '', valor: '' },
                              ]
                            }
                          />
                          <Typography paragraph className={styles.sku}>
                            SKU {productToShow?.sku}
                          </Typography>
                        </Grid>
                      )}
                      {productToShow?.descripcionGeneral && (
                        <Grid
                          container
                          spacing={0}
                          alignItems='center'
                          style={{ marginBottom: '12px' }}
                        >
                          <Typography
                            className={styles.productInfoTextTitle}
                            style={{
                              marginBottom: '10px',
                            }}
                          >
                            Descripción
                          </Typography>
                          <Grid container flexDirection='column'>
                            <Typography
                              paragraph
                              className={styles.description}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: product.descripcionGeneral,
                                }}
                              />
                            </Typography>
                            {productToShow?.documento &&
                              productToShow?.documento?.length > 0 && (
                                <>
                                  <Typography
                                    className={styles.productInfoTextTitle}
                                    style={{
                                      marginBottom: '12px',
                                      marginTop: '53px',
                                    }}
                                  >
                                    Ficha técnica del producto
                                  </Typography>
                                  <Typography
                                    title='Descargar PDF'
                                    className={styles.clickButton}
                                  >
                                    <a
                                      href={
                                        productToShow?.documento &&
                                        productToShow?.documento?.length > 0
                                          ? productToShow?.documento[0]
                                          : '#'
                                      }
                                      target={
                                        productToShow?.documento &&
                                        productToShow?.documento?.length > 0
                                          ? '_blank'
                                          : '_self'
                                      }
                                      download={`fichaTecnica-${productToShow?.sku}.pdf`}
                                      rel='noreferrer'
                                    >
                                      Descargar PDF
                                    </a>
                                  </Typography>
                                </>
                              )}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}
