export const restrictedUsers = new Set([
  '30712268979',
  '30714034215',
  '20104853405',
  '30715755919',
  '30708232153',
  '30593744880',
  '30708123982',
  '30586988359',
  '33562905389',
  '30607413254',
  '30607384319',
  '30707671404',
  '30675963793',
  '30684367958',
  '30716572478',
  '30613985995',
  '30711367485',
  '30714518549',
  '30709022209',
  '30710502931',
  '30622309730',
  '30715657933',
  '30712111131',
  '30501912405',
  '03458420553',
  '30711928142',
  '30708747099',
  '30544398012',
  '30688410890',
  '30708632267',
  '30711615403',
  '30713983205',
  '30619484807',
  '30716462311',
  '30710323034',
  '30707451536',
  '30694856116',
])

const additionalRestrictedUsers = new Set([
  '20331260658',
  '30717194760',
  '20368315975',
  '20278047009',
  '33612480619',
  '33710706439',
  '20244889035',
  '20313834191',
  '20215121225',
  '24058576138',
  '20948323649',
  '30608073643',
  '20169550523',
  '30715229001',
  '30715229001',
  '30708941294',
  '30625035364',
  '20083578115',
  '30709676977',
  '30709243752',
  '30711146349',
  '33714469059',
  '30708577398',
  '33710049209',
  '30708630442',
  '20058313131',
  '33532281259',
  '30616196568',
  '30712041680',
  '20262375073',
  '30534262465',
  '20309509715',
  '20380538483',
  '30710231547',
  '30573020819',
  '33504047089',
  '30710641397',
  '30708630442',
  '30710411766',
  '30608073643',
  '20228991296',
  '33681033969',
  '23344694249',
  '30715801260',
  '20362602433',
  '20059531663',
  '20245921528',
  '30711754799',
  '23276076639',
  '20241406165',
  '20115789466',
  '20252635557',
  '20310226751',
  '23163284499',
  '30714882240',
  '30710566956',
  '20254685675',
  '20204459461',
  '20149838520',
  '30708830611',
  '20291346554',
  '20102818483',
  '20205300652',
  '20313834191',
  '20244889035',
  '20248807262',
  '20203220163',
  '20215674933',
  '30643877909',
  '20164668577',
  '20058630706',
  '20058856097',
  '20059554949',
  '20164669859',
  '20206482630',
  '33523453799',
  '20206985713',
  '20077053396',
  '23120957449',
  '20123726910',
  '20100710456',
  '30708143525',
  '20058596168',
  '30708286989',
  '23172379249',
  '30708577398',
  '20217706786',
  '20115507487',
  '30681042144',
  '23224498209',
  '23250186029',
  '20169550523',
  '20256607779',
  '20237089392',
  '30709243752',
  '30690442589',
  '30521525998',
  '20276076079',
  '20149839233',
  '20179943825',
  '20228659658',
  '30708365226',
  '20058151956',
  '30711984999',
  '20222675570',
  '30709465984',
  '20200976577',
  '20059068386',
  '30604954130',
  '27099852831',
  '20103409846',
  '20171181357',
  '23180075569',
  '23082872299',
  '20299710832',
  '33712388949',
  '20284582919',
  '20276092562',
  '20242366221',
  '20262375464',
  '30712407367',
  '20291345760',
  '30714314269',
  '24058576138',
  '30712006095',
  '20085099389',
  '23108775319',
  '30711509441',
  '20329369111',
  '30711538492',
  '30710231547',
  '27204789679',
  '30616196568',
  '33710049209',
  '30712041680',
  '20180091360',
  '30712427929',
  '30708941294',
  '20134282240',
  '20173350512',
  '20164669670',
  '20160464098',
  '20244433104',
  '20109929442',
  '20058818012',
  '27309509272',
  '20217339953',
  '20272933821',
  '20248781468',
  '20230001279',
  '20218697624',
  '20368315975',
  '30708682604',
  '20262375189',
  '20175480537',
  '20084518752',
  '20180077066',
  '30708361514',
  '20059528204',
  '20246066133',
  '30625035364',
  '20227120240',
  '20100738954',
  '30711928568',
  '20122242383',
  '30708682841',
  '20078864444',
  '30534395678',
  '20291078401',
  '23142524279',
  '20209505585',
  '20137185041',
  '20127427152',
  '20058733017',
  '20252886614',
  '20175481371',
  '20059422864',
  '20131439793',
  '30714963321',
  '23084409189',
  '20119186871',
  '23202995969',
  '27149618282',
  '27246308115',
  '20246308064',
  '30710493649',
  '23130697909',
  '27115457638',
  '20139672705',
  '20201007314',
  '20205900234',
  '20181825252',
  '20278046886',
  '20205055992',
  '20171664897',
  '20201007675',
  '20059466837',
  '20141652053',
  '27207892128',
  '30708558695',
  '20220097073',
  '20115407199',
  '20167986316',
  '20116744652',
  '24261754696',
  '30709559512',
  '20252431943',
  '23140231819',
  '30714614165',
  '30715229001',
  '20083996863',
  '20217707693',
  '27149617723',
  '30715734628',
  '30708369728',
  '20058883280',
  '20164177190',
  '20390337370',
  '20310226301',
  '30708890029',
  '20215455239',
  '30716666685',
  '20185170218',
  '20237089996',
  '30715435213',
  '33716108029',
  '20175480693',
  '20208656393',
  '20049927046',
  '30713549505',
  '20169550698',
  '20299884903',
  '30712132082',
  '33514280229',
  '30711285209',
  '30654844077',
  '20232197839',
  '30710361157',
  '30716527847',
  '20179944945',
  '20257634966',
  '20164175503',
  '20149705830',
  '20215826873',
  '20278258026',
  '30716373564',
  '27054931978',
  '20274829258',
  '20211194295',
  '20293262250',
  '20357062463',
  '20362166692',
  '20307396905',
  '27339412354',
  '23294057439',
  '20305454045',
  '20066103995',
  '20078869659',
  '20100738806',
  '20104115978',
  '20059546504',
  '20110400455',
  '20124316325',
  '20135757498',
  '20126046910',
  '20137589088',
  '20209715032',
  '20229575113',
  '20215961738',
  '20309509715',
  '20262375073',
  '20268311875',
  '20274679671',
  '27141562393',
  '27283584599',
  '30700155710',
  '30707025774',
  '30711146349',
  '30708458569',
  '30715474286',
  '33714469059',
  '30714322989',
  '30716285045',
  '20164669980',
  '30611925731',
  '30670840014',
  '30709676977',
  '20122636780',
  '30670840014',
  '20164669980',
  '30700155710',
  '20206655586',
  '20268311875',
  '20177358844',
  '33602489499',
  '30709367273',
  '27106998278',
  '33709554749',
  '20209713668',
  '30709584665',
  '20234240715',
  '20163120292',
  '20180997777',
  '27216570710',
  '20175973576',
  '20101990878',
  '20240717469',
  '20286784217',
  '20168503068',
  '33711211549',
  '27160346812',
  '20176161575',
  '27276425213',
  '23298553309',
  '30711709173',
  '20171816093',
  '30513381944',
  '20215127002',
  '30520136769',
  '20205275607',
  '30709107778',
  '20243677611',
  '20232180685',
  '23330141999',
  '30631879191',
  '20171924031',
  '20100733162',
  '20043844548',
  '20183584201',
  '30714249386',
  '20231874233',
  '20203611235',
  '23219789289',
  '20206482738',
  '20281102622',
  '20249207706',
  '20219120363',
  '23111010099',
  '27057163254',
  '27258287237',
  '20312018528',
  '20171818053',
  '20334163017',
  '33607129369',
  '30710991258',
  '20345403028',
  '30712395008',
  '20289152157',
  '30716351056',
  '20374288092',
  '20313522211',
  '20240022339',
  '20172199543',
  '33714149399',
  '30669870287',
  '20057035200',
  '30615553308',
  '20104853405',
  '20078479648',
  '20116404797',
  '20104853723',
  '20145232644',
  '30669761852',
  '20167463100',
  '20057385686',
  '20162816684',
  '30669794815',
  '20178518837',
  '30623593823',
  '20085614089',
  '30669840671',
  '20136930622',
  '27176442196',
  '20167469745',
  '33578089239',
  '20160039966',
  '20130823530',
  '20049245743',
  '20207806847',
  '20105414596',
  '20224635495',
  '30546679183',
  '20201830452',
  '20245167955',
  '27276667322',
  '30711713847',
  '30710934971',
  '20172280278',
  '30711928142',
  '30713210605',
  '27305179855',
  '20274526573',
  '33714225249',
  '30714553832',
  '20282441870',
  '20245964685',
  '27321823098',
  '30714296740',
  '30715367293',
  '23317196164',
  '20279292201',
  '20355603165',
  '20292161841',
  '30715942700',
  '30716262622',
  '33715447059',
  '33708394659',
  '30709700681',
  '30715130471',
  '20343611677',
  '27267168224',
  '20105731397',
  '20102857578',
  '20101986307',
  '20102028873',
  '20107588664',
  '20083578115',
  '20083786494',
  '20080397543',
  '20121082536',
  '20135752348',
  '20123371284',
  '20142916704',
  '20184960207',
  '20222669708',
  '20219844051',
  '20207656535',
  '20225174998',
  '20231874101',
  '20247768972',
  '30534262465',
  '27140947356',
  '23220454169',
  '23228379069',
  '30615770791',
  '30708909595',
  '30709862304',
  '33504047089',
  '30714207357',
  '33681062659',
  '33533328119',
  '33708609299',
  '33532281259',
  '33710706439',
  '33709398909',
  '20058313131',
  '20123091427',
  '20148086738',
  '20170722311',
  '20126045582',
  '20230516902',
  '20226610171',
  '30508443427',
  '20180092812',
  '30576157858',
  '20058085562',
  '20128967274',
  '30690468898',
  '20058779130',
  '20063456978',
  '20084922626',
  '20078866285',
  '30708632267',
  '23116462419',
  '30669795978',
  '30688749820',
  '30709394009',
  '27174530624',
  '30645834646',
  '20109709728',
  '20174521507',
  '23058857939',
  '20131196386',
  '20203137274',
])

export const restrictedProducts = new Set([
  '8600001',
  '8600002',
  '8600003',
  '8600004',
  '8600005',
  '8600006',
  '8600007',
  '8600009',
  '8600010',
  '8600011',
  '8600012',
  '8600013',
  '8600014',
  '8600015',
  '8600016',
  '8600017',
  '8600018',
  '8600019',
  '8600020',
  '8600021',
  '8600022',
  '8600023',
  '8600024',
  '8600025',
  '8600026',
  '8600027',
  '8600028',
  '8600029',
  '8600030',
  '8600031',
  '8600032',
  '8600033',
  '8600034',
  '8600035',
  '8600036',
  '8600037',
  '8600038',
  '8600039',
  '8600040',
  '8600041',
  '8600042',
  '8600043',
  '8600044',
  '8600045',
  '8600046',
])

const specialRestrictedProducts = new Set(['8600045', '8600046'])

export const shouldHidePrice = (user, productSku) => {
  // Check if user is in main restrictedUsers list (hides all restrictedProducts)
  if (restrictedUsers.has(user)) {
    return restrictedProducts.has(productSku)
  }

  // Check if user is in additionalRestrictedUsers list (hides only specialRestrictedProducts)
  if (additionalRestrictedUsers.has(user)) {
    return specialRestrictedProducts.has(productSku)
  }

  return false // Otherwise, price should be visible
}
